/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { useContext } from 'react';
import About from '../src/components/views/about/About';
import FeedPage from '../src/components/views/feed/FeedPage';
import PropsContext from '../src/contexts/PropsContext';
import { getWhiteLabelAbout } from '../src/store/actions/whitelabel';

const Home = ({ about }) => {
  const context = useContext(PropsContext);
  const { token } = context;

  if (token) {
    return <FeedPage />;
  }

  return <About aboutSections={about} />;
};

Home.propTypes = {
  about: PropTypes.array,
};

export default Home;

export const getServerSideProps = async ctx => {
  const { token_whitelabel } = ctx.req.cookies;
  try {
    if (!token_whitelabel) {
      const about = await getWhiteLabelAbout();
      return {
        props: {
          about: about.payload.data.data,
        },
      };
    }
    return {
      props: {},
    };
  } catch (error) {
    return {
      props: {},
    };
  }
};
